<template>
  <div class="dimbox register">
    <div class="mHeader">
      <div class="flexL tit">
        <p class="bold">상품 등록</p>
      </div>
      <button @click="close">
        <span class="material-icons"> close </span>
      </button>
    </div>
    <div class="mBody">
      <div class="flexL">
        <p class="bold">
          상품정보 제목
          <span>*</span>
        </p>
        <el-select v-model="title" placeholder="선택" class="searchCondition">
          <el-option
            key="혜택 쿠폰"
            label="혜택 쿠폰"
            value="혜택 쿠폰"
          ></el-option>
        </el-select>
      </div>
      <div class="flexL">
        <p class="bold">참고 이미지</p>
        <div class="imgBox">
          <ul>
            <li v-if="thumbnail">
              <img :src="thumbnail" alt="업로드이미지" />
              <a class="cancel" @click="handleDeleteFile(thumbnail)">
                <img
                  src="@/assets/images/icon_svg/ic_delete_wh.svg"
                  alt="icon"
                />
              </a>
            </li>
            <li v-else>
              <label for="imgUpload">
                <span class="material-icons addBtn">add</span>
              </label>
              <input
                type="file"
                ref="thumbnail"
                @change="sendFile('thumbnail')"
                id="imgUpload"
                accept="image/*"
              />
            </li>
          </ul>
        </div>
      </div>
      <div class="flexL">
        <p class="bold">혜택 제목 <span>*</span></p>
        <input type="text" v-model="service" />
      </div>
      <div class="flexL">
        <p class="bold">혜택 적용 내용</p>
        <input type="text" v-model="svcContents" />
      </div>
      <div class="flexL">
        <p class="bold">혜택 적용 기간</p>
        <el-date-picker
          v-model="applyDate"
          type="daterange"
          range-separator="To"
          start-placeholder="시작 날짜"
          end-placeholder="끝 날짜"
        ></el-date-picker>
      </div>
      <div class="buttonWrap">
        <button @click="close" class="close">취소</button>
        <button class="basic" @click="updateData">
          {{ editMode == true ? "수정" : "등록" }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { uploadFile, deleteFile } from "@/api/file";

export default {
  props: ["plannerId", "select"],
  data() {
    return {
      file: "",
      editMode: false,
      title: "혜택 쿠폰",
      thumbnail: "",
      service: "",
      svcContents: "",
      applyDate: "",
    };
  },
  mounted() {
    if (this.select != null) {
      this.editMode = true;
      this.thumbnail = this.select.thumbnail;
      this.service = this.select.service;
      this.svcContents = this.select.svcContents;
      this.applyDate = this.select.applyDate;
    } else {
      this.editMode = false;
      this.thumbnail = "";
      this.service = "";
      this.svcContents = "";
      this.applyDate = "";
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    updateData() {
      if (this.service === "") {
        this.$alert("혜택 제목을 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }

      let data = {
        planner: this.plannerId,
        title: this.title,
        thumbnail: this.thumbnail,
        service: this.service,
        svcContents: this.svcContents,
        applyDate: this.applyDate,
      };
      this.$emit("updateData", data);
      this.$emit("close");
    },
    sendFile() {
      if (this.thumbnail) {
        this.$alert("썸네일 삭제 후 업로드 해주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }
      this.file = this.$refs.thumbnail.files[0];
      if (this.file == undefined) {
        return;
      }
      let formdata = new FormData();
      formdata.append("file", this.file);
      uploadFile(formdata).then(async (res) => {
        if (res.status == 200) {
          if (res.data.status == 200) {
            this.thumbnail = res.data.url;
            this.file = "";
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
            return;
          }
        } else {
          this.$alert("서버오류 입니다. 다시 파일을 업로드 해 주세요.", {
            confirmButtonText: "확인",
            center: true,
          });
          return;
        }
      });
    },
    handleDeleteFile(url) {
      this.$confirm("이미지를 삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        deleteFile(url).then((res) => {
          if (res.data.status == 200) {
            this.thumbnail = "";
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      });
    },
    // sendFile() {
    //   if (this.file == undefined) {
    //     return;
    //   }
    //   this.file = this.$refs.images.files[0];
    //   let formdata = new FormData();
    //   formdata.append("file", this.file);

    //   uploadFile(formdata).then(async (res) => {
    //     if (res.status == 200) {
    //       if (res.data.status == 200) {
    //         this.images.push(res.data.url);
    //         this.file = "";
    //       } else {
    //         this.$alert(res.data.message, {
    //           confirmButtonText: "확인",
    //           center: true,
    //         });
    //         return;
    //       }
    //     } else {
    //       this.$alert("서버오류 입니다. 다시 파일을 업로드 해 주세요.", {
    //         confirmButtonText: "확인",
    //         center: true,
    //       });
    //       return;
    //     }
    //   });
    // },
    // handleDeleteFile(url) {
    //   this.$confirm("이미지를 삭제하시겠습니까?", {
    //     confirmButtonText: "삭제",
    //     cancelButtonText: "취소",
    //     center: true,
    //   }).then(() => {
    //     deleteFile(url).then((res) => {
    //       if (res.data.status == 200) {
    //         this.images.splice(this.images.indexOf(url), 1);
    //       } else {
    //         this.$alert(res.data.message, {
    //           confirmButtonText: "확인",
    //           center: true,
    //         });
    //       }
    //     });
    //   });
    // },
  },
};
</script>
