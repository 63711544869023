<template>
  <div class="dimbox register">
    <div class="mHeader">
      <div class="flexL tit">
        <p class="bold">포트폴리오 등록</p>
      </div>
      <button @click="close">
        <span class="material-icons"> close </span>
      </button>
    </div>
    <div class="mBody">
      <div class="flexL">
        <p class="bold">
          포트폴리오 이름
          <span>*</span>
        </p>
        <input type="text" v-model="title" />
      </div>
      <div class="flexL">
        <p class="bold">
          포트폴리오 설명
          <span>*</span>
        </p>
        <textarea v-model="content" />
      </div>
      <div class="flexL">
        <p class="bold">
          포트폴리오 대표사진
          <span>*</span>
        </p>
        <div>
          <div class="flexB">
            <div class="filebox">
              <label for="thumbnail">찾아보기</label>
              <input
                type="file"
                ref="thumbnail"
                id="thumbnail"
                @change="sendFile('thumbnail')"
                accept="image/*"
              />
            </div>
          </div>
          <div class="flex" v-if="thumbnail">
            <div class="imgWrap">
              <img :src="thumbnail" alt="이미지" id="img" />
              <a
                class="cancel"
                @click="handleDeleteFile(thumbnail, 'thumbnail')"
              >
                <img
                  src="@/assets/images/icon_svg/ic_delete_wh.svg"
                  alt="icon"
                />
              </a>
            </div>
          </div>
          <em class="notice"
            >대표사진은 16:9 비율로 업로드 됩니다. ex) 500 * 281</em
          >
        </div>
      </div>
      <div class="flexL multiple">
        <p class="bold">
          태그
          <span>*</span>
        </p>
        <ol>
          <li><span>①</span><input type="text" v-model="tag1" /></li>
          <li><span>②</span><input type="text" v-model="tag2" /></li>
          <li><span>③</span><input type="text" v-model="tag3" /></li>
        </ol>
      </div>
      <!--    <div class="flexL">
        <p class="bold">
          분류
          <span>*</span>
        </p>
        <el-select
          v-model="category"
          multiple
          placeholder="선택"
          class="searchCondition"
          @change="handleCategory"
        >
          <el-option key="스튜디오" label="스튜디오" value="스튜디오">
          </el-option>
          <el-option key="드레스" label="드레스" value="드레스"> </el-option>
          <el-option
            key="헤어/메이크업"
            label="헤어/메이크업"
            value="헤어/메이크업"
          >
          </el-option>
          <el-option key="예복" label="예복" value="예복"> </el-option>
          <el-option key="본식스냅" label="본식스냅" value="본식스냅">
          </el-option>
          <el-option key="본식영상" label="본식영상" value="본식영상">
          </el-option>
          <el-option key="웨딩디렉팅" label="웨딩디렉팅" value="웨딩디렉팅">
          </el-option>
        </el-select>
      </div>-->
      <div class="flexL">
        <p class="bold">가격</p>
        <input type="text" v-model="fee" />
      </div>
      <div class="flexL">
        <p class="bold">웨딩홀</p>
        <el-select v-model="weddingPlace" clearable placeholder="선택">
          <el-option
            v-for="item in placeList"
            :key="item.value"
            :label="item.title"
            :value="item._id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="flexL">
        <p class="bold">연관 브랜드</p>
        <el-select v-model="collections" multiple placeholder="선택">
          <el-option
            v-for="item in collectionList"
            :key="item.value"
            :label="item.title"
            :value="item._id"
          >
          </el-option>
        </el-select>
      </div>
      <!-- <div class="flexL" v-if="category.includes('DRESS')">
        <p class="bold">드레스/예복</p>
        <el-select
          v-model="dress"
          filterable
          remote
          reserve-keyword
          placeholder="선택"
          @change="handleCollections"
        >
          <el-option
            v-for="item in collectionList.filter(
              (item) => item.category == 'DRESS'
            )"
            :key="item.value"
            :label="item.title"
            :value="item._id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="flexL" v-if="category.includes('MAKEUP')">
        <p class="bold">헤어&#38;메이크업/부케</p>
        <el-select
          v-model="hairMakeup"
          filterable
          remote
          reserve-keyword
          placeholder="선택"
          @change="handleCollections"
        >
          <el-option
            v-for="item in collectionList.filter(
              (item) => item.category == 'MAKEUP'
            )"
            :key="item.value"
            :label="item.title"
            :value="item._id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="flexL" v-if="category.includes('HONEYMOON')">
        <p class="bold">허니문</p>
        <el-select
          v-model="honeymoon"
          filterable
          remote
          reserve-keyword
          placeholder="선택"
          @change="handleCollections"
        >
          <el-option
            v-for="item in collectionList.filter(
              (item) => item.category == 'HONEYMOON'
            )"
            :key="item.value"
            :label="item.title"
            :value="item._id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="flexL" v-if="category.includes('VIDEO')">
        <p class="bold">웨딩영상</p>
        <el-select
          v-model="video"
          filterable
          remote
          reserve-keyword
          placeholder="선택"
          @change="handleCollections"
        >
          <el-option
            v-for="item in collectionList.filter(
              (item) => item.category == 'VIDEO'
            )"
            :key="item.value"
            :label="item.title"
            :value="item._id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="flexL" v-if="category.includes('EVENT')">
        <p class="bold">주례&#38;사회/이벤트</p>
        <el-select
          v-model="event"
          filterable
          remote
          reserve-keyword
          placeholder="선택"
          @change="handleCollections"
        >
          <el-option
            v-for="item in collectionList.filter(
              (item) => item.category == 'EVENT'
            )"
            :key="item.value"
            :label="item.title"
            :value="item._id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="flexL" v-if="category.includes('GIFT')">
        <p class="bold">예물</p>
        <el-select
          v-model="gift"
          filterable
          remote
          reserve-keyword
          placeholder="선택"
          @change="handleCollections"
        >
          <el-option
            v-for="item in collectionList.filter(
              (item) => item.category == 'GIFT'
            )"
            :key="item.value"
            :label="item.title"
            :value="item._id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="flexL" v-if="category.includes('HANBOK')">
        <p class="bold">한복/예단</p>
        <el-select
          v-model="hanbok"
          filterable
          remote
          reserve-keyword
          placeholder="선택"
          @change="handleCollections"
        >
          <el-option
            v-for="item in collectionList.filter(
              (item) => item.category == 'HANBOK'
            )"
            :key="item.value"
            :label="item.title"
            :value="item._id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="flexL" v-if="category.includes('PYEBAEK')">
        <p class="bold">폐백&#38;이바지/청첩장</p>
        <el-select
          v-model="pyebaek"
          filterable
          remote
          reserve-keyword
          placeholder="선택"
          @change="handleCollections"
        >
          <el-option
            v-for="item in collectionList.filter(
              (item) => item.category == 'PYEBAEK'
            )"
            :key="item.value"
            :label="item.title"
            :value="item._id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="flexL" v-if="category.includes('BEAUTY')">
        <p class="bold">뷰티케어</p>
        <el-select
          v-model="beauty"
          filterable
          remote
          reserve-keyword
          placeholder="선택"
          @change="handleCollections"
        >
          <el-option
            v-for="item in collectionList.filter(
              (item) => item.category == 'BEAUTY'
            )"
            :key="item.value"
            :label="item.title"
            :value="item._id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="flexL" v-if="category.includes('INTERIOR')">
        <p class="bold">가전/가구/인테리어</p>
        <el-select
          v-model="interior"
          filterable
          remote
          reserve-keyword
          placeholder="선택"
          @change="handleCollections"
        >
          <el-option
            v-for="item in collectionList.filter(
              (item) => item.category == 'INTERIOR'
            )"
            :key="item.value"
            :label="item.title"
            :value="item._id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="flexL" v-if="category.includes('CATERING')">
        <p class="bold">케이터링</p>
        <el-select
          v-model="catering"
          filterable
          remote
          reserve-keyword
          placeholder="선택"
          @change="handleCollections"
        >
          <el-option
            v-for="item in collectionList.filter(
              (item) => item.category == 'CATERING'
            )"
            :key="item.value"
            :label="item.title"
            :value="item._id"
          >
          </el-option>
        </el-select>
      </div> -->

      <div class="flexL">
        <p class="bold">
          포트폴리오 사진
          <span>*</span>
        </p>
        <div>
          <div class="flexB">
            <div class="filebox">
              <label for="images">찾아보기</label>
              <input
                type="file"
                ref="images"
                id="images"
                @change="sendFile('images')"
                accept="image/*"
                multiple
              />
            </div>
          </div>
          <div class="flex images" v-if="images.length > 0">
            <div v-for="(image, i) in images" :key="i" class="imgWrap">
              <img :src="image" alt="이미지" id="img" />
              <a class="cancel" @click="handleDeleteFile(image, 'images')">
                <img
                  src="@/assets/images/icon_svg/ic_delete_wh.svg"
                  alt="icon"
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="buttonWrap">
        <button @click="close" class="close">취소</button>
        <button class="basic" @click="submit">
          {{ editMode == true ? "수정" : "등록" }}
        </button>
        <button v-if="editMode" class="line" @click="handleDelete">삭제</button>
      </div>
    </div>
  </div>
</template>
<script>
import {
  createPortfolio,
  fetchPortfolioDetail,
  removePortfolio,
  upadatePortfolio,
  fetchCollectionList,
  fetchWeddingPlaceList,
} from "@/api/admin";
import { uploadFile, deleteFile, deletePostFile } from "@/api/file";

export default {
  props: ["plannerId", "portfolioId"],
  data() {
    return {
      editMode: false,
      title: "",
      category: [],
      content: "",
      tag1: "",
      tag2: "",
      tag3: "",

      studio: "",
      dress: "",
      hairMakeup: "",
      honeymoon: "",
      video: "",
      event: "",
      gift: "",
      hanbok: "",
      pyebaek: "",
      beauty: "",
      interior: "",
      catering: "",

      robe: "",
      fee: "",
      images: [],
      thumbnail: "",
      collectionList: [],
      placeList: [],
      collections: [],
      weddingPlace: null,
    };
  },
  watch: {
    fee: function (newValue) {
      const result = newValue
        .toString()
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.fee = result;
    },
  },
  mounted() {
    if (this.portfolioId) {
      this.getPortfolioDetail();
    }
    this.getCollectionList();
    this.getPlaceList();
  },
  methods: {
    getPlaceList() {
      let params = {
        page: 1,
        limit: 100000,
      };
      fetchWeddingPlaceList(params).then((res) => {
        if (res.data.status == 200) {
          this.placeList = res.data.data;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
    getCollectionList() {
      let params = {
        page: 1,
        limit: 100000,
      };
      fetchCollectionList(params).then((res) => {
        if (res.data.status == 200) {
          this.collectionList = res.data.data;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },

    close() {
      this.$emit("close");
      if (this.editMode) {
        this.$emit("getPortfolio");
      }
    },

    uncomma(str) {
      str = String(str);
      return str.replace(/[^\d]+/g, "");
    },

    getPortfolioDetail() {
      fetchPortfolioDetail(this.portfolioId).then((res) => {
        if (res.data.status == 200) {
          this.editMode = true;
          this.title = res.data.data.title;
          this.category = res.data.data.category;
          this.content = res.data.data.content;
          this.tag1 = res.data.data.tag[0];
          this.tag2 = res.data.data.tag[1];
          this.tag3 = res.data.data.tag[2];
          this.dress = res.data.data.dress;
          this.studio = res.data.data.studio;
          this.hairMakeup = res.data.data.hairMakeup;
          this.robe = res.data.data.robe;
          this.fee = res.data.data.fee;
          this.images = res.data.data.images;
          this.thumbnail = res.data.data.thumbnail;
          this.collections = res.data.data.collections;
          this.weddingPlace = res.data.data.weddingPlace;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
    sendFile(type) {
      if (type == "thumbnail") {
        if (this.thumbnail) {
          this.$alert("썸네일 삭제 후 업로드 해주세요.", {
            confirmButtonText: "확인",
            center: true,
          });
          return;
        }
        this.file = this.$refs.thumbnail.files[0];
      } else if (type == "images") {
        this.file = this.$refs.images.files[0];
      }
      if (this.file == undefined) {
        return;
      }
      let formdata = new FormData();
      formdata.append("file", this.file);

      uploadFile(formdata).then(async (res) => {
        if (res.status == 200) {
          if (res.data.status == 200) {
            if (type == "thumbnail") {
              this.thumbnail = res.data.url;
            } else if (type == "images") {
              this.images.push(res.data.url);
            }
            this.file = "";
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        } else {
          this.$alert("서버오류 입니다. 다시 파일을 업로드 해 주세요.", {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
    handleDeleteFile(url, type) {
      this.$confirm("이미지를 삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        if (this.storeId) {
          deletePostFile(this.storeId, url, type).then((res) => {
            if (res.data.status == 200) {
              if (type == "thumbnail") {
                this.thumbnail = "";
              } else if (type == "images") {
                this.images.splice(this.images.indexOf(url), 1);
              }
            } else {
              this.$alert(res.data.message, {
                confirmButtonText: "확인",
                center: true,
              });
            }
          });
        } else {
          deleteFile(url).then((res) => {
            if (res.data.status == 200) {
              if (type == "thumbnail") {
                this.thumbnail = "";
              } else if (type == "images") {
                this.images.splice(this.images.indexOf(url), 1);
              }
            } else {
              this.$alert(res.data.message, {
                confirmButtonText: "확인",
                center: true,
              });
            }
          });
        }
      });
    },
    handleDelete() {
      this.$confirm("삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        removePortfolio(this.portfolioId).then((res) => {
          if (res.data.status == 200) {
            this.$notify({
              title: "Success",
              message: "포트폴리오 정보가 삭제 되었습니다.",
              type: "success",
            });
            this.close();
          }
        });
      });
    },
    submit() {
      if (this.title == "") {
        this.$alert("포트폴리오 이름을 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.content == "") {
        this.$alert("포트폴리오 설명을 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.thumbnail == "") {
        this.$alert("대표사진을 등록해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.tag1 == "" && this.tag2 == "" && this.tag3 == "") {
        this.$alert("태그를 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
        // } else if (this.category == "") {
        //   this.$alert("분류를 선택해 주세요.", {
        //     confirmButtonText: "확인",
        //     center: true,
        //   });
        //   return;
      } else if (this.images == "") {
        this.$alert("포트폴리오 사진을 등록해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }

      let data = {
        planner: this.plannerId,
        portfolioId: this.portfolioId,
        title: this.title,
        category: this.category,
        content: this.content,
        tag: [this.tag1, this.tag2, this.tag3],
        dress: this.dress,
        studio: this.studio,
        hairMakeup: this.hairMakeup,
        robe: this.robe,
        fee: this.uncomma(this.fee),
        images: this.images,
        thumbnail: this.thumbnail,
        collections: this.collections,
        weddingPlace: this.weddingPlace == "" ? null : this.weddingPlace,
      };
      if (this.editMode) {
        upadatePortfolio(data).then((res) => {
          if (res.data.status == 200) {
            this.$alert("포트폴리오 정보가 반영 되었습니다.", {
              confirmButtonText: "확인",
              center: true,
            }).then(() => {
              this.close();
            });
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      } else {
        createPortfolio(data).then((res) => {
          if (res.data.status == 200) {
            this.$alert("포트폴리오 등록이 완료 되었습니다.", {
              confirmButtonText: "확인",
              center: true,
            }).then(() => {
              this.close();
            });
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      }
    },
  },
};
</script>
